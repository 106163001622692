<template>
  <div class="app-container invoice-account">
    <div class="filter-container" style="min-height: auto;width: 100%">
      <el-form :inline="true" :model="queryForm" ref="queryForm" class="form-inline tz" style="width: 100%">
        <el-row style="width: 100%">
          <el-form-item label="开票日期" label-width="15%"  style="width: 30%" class="range-query">
            <el-date-picker
                v-model="startDate"
                type="date"
                placeholder="开始日期"
                :picker-options="startDatePicker"
                style="width: 45%"
                format="yyyy年MM月dd日"
                value-format="yyyy-MM-dd"
            />
              至
            <el-date-picker
                v-model="endDate"
                type="date"
                placeholder="结束日期"
                :picker-options="endDatePicker"
                style="width: 45%"
                format="yyyy年MM月dd日"
                value-format="yyyy-MM-dd"
            />
          </el-form-item>
          <el-form-item label="发票类型" prop="comboSearch" style="width: 17%" label-width="40%" class="single_query">
            <el-select v-model="queryForm.invoiceType" placeholder="请选择" clearable  class="filter-item" >
              <el-option v-for="item in invoiceTypeOptions" :key="item.value" :label="item.key" :value="item.value"/>
            </el-select>
          </el-form-item>
          <el-form-item label="销售方名称" prop="textSearch" style="width: 17%" label-width="35%" class="single_query">
            <el-input placeholder="请输入查询条件" v-model="queryForm.sellerName" maxlength="50"  class="filter-item" @keyup.enter.native="handleQuery"/>
          </el-form-item>
          <el-form-item label="购买方名称" prop="textSearch" style="width: 19%" label-width="35%" class="single_query">
            <el-input placeholder="请输入查询条件" v-model="queryForm.buyerName" maxlength="50" class="filter-item" @keyup.enter.native="handleQuery"/>
          </el-form-item>
          <el-form-item label="开票人" prop="textSearch" style="width: 15%" label-width="35%" class="single_query">
            <el-input placeholder="请输入开票人" v-model="queryForm.drawer" maxlength="50" class="filter-item" @keyup.enter.native="handleQuery"/>
          </el-form-item>
            
        </el-row>
        <el-row style="width: 100%">
          <el-form-item label="发票号码" prop="textSearch" label-width="15%" style="width: 30%" class="range-query removeControl">
            <el-input placeholder="请输入查询条件" v-model="queryForm.invoiceNoStart" oninput="if(value.length>25)value=value.slice(0,25)"  class="filter-item " type='number' :controls='false' @keyup.enter.native="handleQuery"/>
            至
            <el-input placeholder="请输入查询条件" v-model="queryForm.invoiceNoEnd" oninput="if(value.length>25)value=value.slice(0,25)" class="filter-item" type='number' @keyup.enter.native="handleQuery"/>
          </el-form-item>
          <el-form-item label="商品/服务名称" prop="textSearch" style="width: 17%" label-width="40%" class="single_query">
            <el-input placeholder="请输入查询条件" v-model="queryForm.productName" maxlength="50" class="filter-item" @keyup.enter.native="handleQuery"/>
          </el-form-item>
          <el-form-item label="发票代码" prop="textSearch" style="width: 17%" label-width="35%" class="single_query removeControl">
            <el-input placeholder="请输入查询条件" v-model="queryForm.invoiceCode" oninput="if(value.length>25)value=value.slice(0,25)" class="filter-item" type='number'  @keyup.enter.native="handleQuery"/>
          </el-form-item>
          <el-form-item label="发票状态" prop="comboSearch" style="width: 19%" label-width="35%" class="single_query">
            <multipleSelect
                :selectOptions="selectOptions"
                :mulSelectLoading="mulSelectLoading"
                :mulSelecteds="queryForm.invoiceStatus"
                @update:updateMultipleSelect="updateMultipleSelect" class="filter-item">
            </multipleSelect>
          </el-form-item>
          <el-form-item label="开票类型" prop="comboSearch" style="width: 15%" label-width="35%" class="single_query">
            <el-select v-model="queryForm.billingType" placeholder="请选择" clearable  class="filter-item" >
              <el-option v-for="item in billingTypeOptions" :key="item.value" :label="item.key" :value="item.value"/>
            </el-select>
          </el-form-item>
        </el-row>
        <el-row style="width: 100%">
        <el-form-item style="width: 15%" class="button_query">
          <el-button v-waves class="filter-item" style="margin-left: 10%;" type="primary" icon="el-icon-search" @click="handleQuery" ref="search" v-auth='"invoiceAccountWrite:lookup"'>搜索</el-button>
          <el-button v-waves class="filter-item" style="margin-left: 10px;" type="primary" icon="el-icon-refresh" @click="handleReset()" v-auth='"invoiceAccountWrite:lookup"'>重置</el-button>
        </el-form-item>
        </el-row>
      </el-form>
    </div>

    <div class="batch_button" >
      <div class="btnBox">
        <div>
          <el-button class="btn1 iconfont iconicon-03" style="margin-left: 20px" @click="imports" v-auth='"invoiceAccountWrite:import"'>&nbsp;发票导入</el-button>
          <el-button class="btn1 iconfont iconicon-04" @click="batchExport" v-auth='"invoiceAccountWrite:export"'>&nbsp;发票导出</el-button>
          <el-button class="btn2 iconfont iconicon-05" @click="batchDelete" v-auth='"invoiceAccountWrite:delete"'>&nbsp;删除</el-button>
          <el-button class="btn2 iconfont iconicon-06" @click="printPDF" v-auth='"invoiceAccountWrite:print"'>&nbsp;打印</el-button>
        </div>
        <div class="showAllCompany">
          <el-checkbox v-model="queryForm.showAllCompany" @keyup.enter.native="handleQuery" @change="handleQuery" v-auth='"invoiceAccountWrite:lookup"'>显示所有公司发票</el-checkbox>
        </div>
        <div class="zhineng">
          <el-button class="btn1 iconfont iconzhineng"  @click="getInvoiceBtn" v-auth='"invoiceAccountWrite:smart"'>&nbsp;智能取票</el-button>
          <i class="el-icon-question" @mouseenter="tipState=true" @mouseleave="tipState=false"></i>
          <!-- <div class="tip" v-show="true"> -->
          <div class="tip" v-show="tipState">
            <p>智能取票流程：</p>
            <p>1. 点击“智能取票”按钮。 </p>
            <p>2. 输入“授权码”，完成授权。 </p>
            <p>3. 输入取票期间，自动采集发票。 </p>
            <p>4. 支持获取：电子专票、电子普票、纸质普票（不含卷票）、纸质专票</p>
          </div>
        </div>
      </div>
    </div>
    <div class="table">
      <comps-table :tableData="tableData" :tableHeader="tableHeader" v-loading='isLoading' :hideSinglePage='true' class="comp_table" v-if="hasData"
                   :selectionType='true' @multipleSelection='selection' :total='total' :pageSize='queryForm.rows' @current-events="handleCurrentChange" @size-events="changeSize" :pageSizeList='pageSizeList' :page='queryForm.page' height="84%">
      </comps-table>
      <nodata v-else message="暂无数据" class="no_data" v-loading="isLoading" />
    </div>

    <del-dialog :visible="delDialog" :cancelDialog="cancelDialog" :confirmDialog="confirmDialog" :isdeling="isdeling" :config="config" :width="delDialogWidth">
      <div class="answer">确认删除吗？</div>
    </del-dialog>

    <!-- <pdf-dialog :visible='pdfVis' :cancelDialog='pdfCancel' :title="pdfName" :itemId="itemId" :imgUrl="imgUrl" ref="pdf"/> -->
    <!-- <ofd-dialog :visible='ofdVis' :cancelDialog='ofdCancel' :title="ofdName" :itemId="itemId" ref="ofd"/> -->
    <import-dialog  :visible='importVis' :cancelDialog='importCancel' :requestFrom="requestFrom" :invoiceType="invoiceType" :isOpenEinv="isOpenEinv"/>
    <export-dialog :visible='exportVis' :title="title" @selectType="selectType" :isdeling='isdeling' :confirmDialog='confirmExport' :cancelDialog='exportCancel' ref="export"/>
    <get-invoice :visible='getInvoiceVis' :title="title" :cancelDialog='getInvoiceCancel' :isdeling='isdeling' ref="getInvoice" :subjectType='invoiceType'/>

    <!-- V1.5.0 -- 新增功能 -->
    <preview-dialog  ref="pdf"/>
    <!-- <previews-dialog ref="pdf" /> -->
    <push-dialog :visible='pushVis' :cancelDialog='pushCancel' :confirmDialog="pushConfirm" :isdeling='isdeling'/>
  </div>
</template>

<script>
  import delDialog from '@/components/Dialog'
  import compsTable from "@/components/table/table";
  import importDialog from "@/components/importInvoice"
  import multipleSelect from '@/components/multiSelectDropDownBox';
  import exportDialog from './dialog/export' //批量上传发票弹窗
  import checkDialog from './dialog/export' //批量上传发票弹窗
  import previewDialog from '@/components/previewDialog'
  import previewsDialog from './dialog/previews'
  import pushDialog from './dialog/push'
  import getInvoice from '../../../components/getInvoice/getInvoice.vue'
  import axios from 'axios'
  // import {getInvoicePicture}  from '@/api/eInv'
  import request from "@/utils/request";
  import moment from 'moment'
import { time } from 'echarts/core';
  export default {
    components: { delDialog ,compsTable , importDialog ,exportDialog,getInvoice,previewDialog,multipleSelect,previewsDialog,pushDialog },
    data() {
      return {
        queryForm: {
          showAllCompany: false,
          type: '2', //销项
          invoiceTimeStart: '',
          invoiceTimeEnd: '',
          invoiceType: '',
          buyerName: '',
          drawer:'', //开票人
          billingType :'', //开票类型
          invoiceStatus: [],
          invoiceNoStart: '',
          invoiceNoEnd: '',
          productName: '',
          invoiceCode: '',
          page: 1,
          rows: 20
        },
        exportFrom:{},
        isExport:false,//是否可以导出
        total: 0,
        pageSizeList: [20, 50, 100, 200],
        startDate: moment(new Date().getTime() - 365 * 4 * 24 * 3600 * 1000 - (1 * 24 * 3600 * 1000)).format('YYYY-MM-DD'),
        endDate: moment(new Date().getTime()).format('YYYY-MM-DD'),
        startDatePicker: this.beginDate(),
        endDatePicker: this.processDate(),
        invoiceTypeOptions:[{'key': '电子普票','value': '1'},{'key': '电子专票','value': '2'},{'key': '纸质普票','value': '3'},{'key': '纸质专票','value': '4'},{'key': '全电普票','value': '5'},{'key': '全电专票','value': '6'}],
        billingTypeOptions:[{'key': '正数发票','value': '1'},{'key': '负数发票','value': '2'}],
        isLoading: false,
        hasData: true,
        pdfName:'',
        ofdName:'',
     
        tableHeader: [
          { prop: "invoiceCode", label: "发票代码", center: 'center',width:'120'},
          { prop: "invoiceNo", label: "发票号码", center: 'center',width:'120'},
          { prop: "invoiceType", label: "发票类型", center: 'center',width:'120',
            formatter: (row) => {
              const typeMap = {
                '1': '电子普票',
                '2': '电子专票',
                '3': '纸质普票',
                '4': '纸质专票',
                '5': '全电普票',
                '6': '全电专票'
              }
              return typeMap[row.invoiceType];
            },
          },
          { prop: "invoiceTime", label: "开票日期", center: 'center',width:'120',
            formatter: (row) => {
              return row.invoiceTime.slice(0,10);
            },
          },
          // 开票类型
          { prop: "billingType", label: "开票类型", center: 'center',width:'120',
            formatter: (row) => {
              const typeMap = {
                '1': '正数发票',
                '2': '负数发票',
              }
              return typeMap[row.billingType];
            },
            // render: (h, data) => {
            //   return (data.row.billingType ==  1 ?<span>正数发票</span>:<span style="color:red">负数发票</span>)
            // },
          },
          
          { prop: "invoiceAmount", label: "开票金额（元）", center: 'center',width:'140',
            render: (h, data) => {
              return (data.row.invoiceAmount>0?<span>{data.row.invoiceAmount}</span>:<span style="color:red">{data.row.invoiceAmount}</span>)
            },
          },
          { prop: "drawer", label: "开票人", center: 'center',width:'120'},
          { prop: "buyerName", label: "购买方名称", center: 'center',width:'120'},
          // { prop: "buyerTaxId", label: "购买方税号", center: 'center',width:'120'},
          { prop: "invoiceStatus", label: "发票状态", center: 'center',width:'120',
            formatter: (row) => {
              const typeMap = {
                '1': '正常',
                '4': '正常',
                '3': '已红冲',
                '2': '已作废',
                '8': '失控',
              }
              return typeMap[row.invoiceStatus];
            },
            // render: (h, data) => {
            //   return (data.row.invoiceAmount>0?<span>正数发票</span>:<span style="color:red">负数发票</span>)
            // },
          },
          { prop: "productName", label: "商品/服务名称", center: 'center',width:'160',tooltip:true},
          { prop: "sellerName", label: "销售方名称", center: 'center',width:'120'},
          // { prop: "sellerTaxId", label: "销售方税号", center: 'center',width:'120'},
          // { prop: "taxRate", label: "开票税率", center: 'center',width:'120'},
          // { prop: "taxAmount", label: "税额", center: 'center',width:'120'},
          { prop: "remark", label: "备注", center: 'center',width:'120',tooltip:true,
            render: (h, data) => {
              return h("span",{domProps:{
                  innerHTML:data.row.remark==null?'':`<span>`+data.row.remark+`</span>`
                }})
            }},
          // { prop: "sourceInvoiceCode", label: "原发票代码", center: 'center',width:'120'},
          // { prop: "sourceInvoiceNo", label: "原发票号码", center: 'center',width:'120'},
          { prop: "dataFrom", label: "数据来源", center: 'center',width:'120',
            formatter: (row) => {
              const typeMap = {
                '0': '手工导入',
                '1': '智能取票',
                '2': '记账软件',
                '3': '手工开票',
                '4': 'API开票',
              }
              return typeMap[row.dataFrom];
            },
          },
          // { prop: "saveType", label: "版式文件", center: 'center',width:'120',
          //   render: (h, data) => {
          //     const typeMap = {
          //       '1': 'PDF',
          //       '2': 'OFD',
          //       '3': 'JPG',
          //       '4': 'PNG',
          //       '5': 'JPEG',
          //       '6': 'BMP',
          //       '7': 'GIF'
          //     }
          //     return (
          //         <span>
          //           <el-button class='button'  type="text" onClick={() => { this.getFileUrl(data.row);}}>
          //             {typeMap[data.row.saveType]}
          //           </el-button>
          //         </span>
          //     );
          //   },
          // },
          { label: "操作",width:'160', center: 'center', fixed:'right' ,
            render: (h, data) => {
              if(data.row.billingType == '1' && (data.row.dataFrom == '3' || data.row.dataFrom == '4') && data.row.invoiceStatus != '3' && data.row.invoiceStatus != '2'){ //正数发票、手工开票
                return (
                  <span style="display: flex; justify-content: space-around;">
                    <el-button class='button' type="text" v-auth="invoiceAccountWrite:view" onClick={() => { this.preview(data.row);}}>
                      预览
                    </el-button>
                    <el-button class='button' type="text" v-auth="invoiceAccountWrite:push" onClick={() => { this.push(data.row);}}>
                      推送
                    </el-button>
                    <el-button class='button' type="text" v-auth="invoiceAccountWrite:red" onClick={() => { this.redFlush(data.row);}}>
                      冲红
                    </el-button>
                  </span>
                )
              }else if(data.row.dataFrom == '3' || data.row.dataFrom == '4'){  //手工开票,发票状态：红冲、作废
                return (
                  <span style="display: flex; justify-content: space-around;">
                    <el-button class='button' type="text" v-auth="invoiceAccountWrite:view" onClick={() => { this.preview(data.row);}}>
                      预览
                    </el-button>
                    <el-button class='button' type="text" v-auth="invoiceAccountWrite:push" onClick={() => { this.push(data.row);}}>
                      推送
                    </el-button>
                  </span>
                )
              }else{
                return (  // 非手工开票
                  <span style="display: flex; justify-content: space-around;">
                    <el-button class='button' type="text" v-auth="invoiceAccountWrite:view" onClick={() => { this.preview(data.row);}}>
                      预览
                    </el-button>
                  </span>
                ) 
              }
            },
          }
        ],
        tableData: [],
        isdeling: false,
        config: {
          top: '20vh',
          width: '300px',
          title: '提示',
          btnTxt: ["确定", "取消"]
        },
        delDialog: false,
        importVis: false,
        pdfVis: false,
        ofdVis: false,
        exportVis: false,
        getInvoiceVis:false,
        exportType:'2',
        title: '销项发票导出',
        multipleSelection: [],
        promptVisible : false,
        icon : 'iconfont iconicon1-17',
        color: '#35C4B1',
        message : '修改提示',
        printTitle: '发票打印',
        tipState:false,
        // subjectType:'2',
        requestFrom:"einv",
        isOpenEinv:"0",
        invoiceType:"2",
        importsIsdeling:false,
        delDialogWidth:"300px",
        isSearch:this.$route.query.isSearch || false,
        selectOptions:[],
        mulSelectLoading:false,

        // itemId:'null',
        // imgUrl:'https://fuss10.elemecdn.com/e/5d/4a731a90594a4af544c0c25941171jpeg.jpeg',
        // previewsVis:false,

        // V1.5.0 -- 新增功能
        pushVis:false,
        rowData:{},
      }
    },
    methods: {
      beginDate(){
        const self = this
        return {
          disabledDate(time){
            if (self.endDate) {
              //如果结束时间不为空，则小于结束时间
              //时区转换
              let ntime = new Date(time.getTime())
              ntime.setHours(ntime.getHours() + 8)
              let curDate = new Date().getTime()
              let Years = 365 * 4 * 24 * 3600 * 1000;  //四年的时间戳
              let smallTimes = new Date(self.endDate).getTime() < ( curDate - Years) ? new Date(self.endDate).getTime() : ( curDate - Years - (2 * 24 * 3600 * 1000) );
              return  ntime.getTime() < smallTimes || ntime.getTime() > (new Date().getTime()); 
            }else{
              let curDate = new Date().getTime()
              let Years = 365 * 4 * 24 * 3600 * 1000;  //四年的时间戳
              return time.getTime() < (curDate - Years) || time.getTime() > (curDate); 
            }
          }
        }
      },
      processDate() {
        const  self = this
        return {
          disabledDate(time) {
            if (self.startDate) {  //如果开始时间不为空，则结束时间大于开始时间
              //时区转换
              let ntime = new Date(time.getTime())
              ntime.setHours(ntime.getHours() + 8)
              let curDate = new Date().getTime()
              let Years = 365 * 4 * 24 * 3600 * 1000;  //四年的时间戳
              let smallTimes = new Date(self.startDate).getTime() < (curDate - Years) ? ( curDate - Years - (2 * 24 * 3600 * 1000)) :  new Date(self.startDate).getTime();
              return  ntime.getTime() < smallTimes || ntime.getTime() > (new Date().getTime()); 
            }else{
              let curDate = new Date().getTime()
              let Years = 365 * 4 * 24 * 3600 * 1000;  //四年的时间戳
              return time.getTime() < (curDate - Years) || time.getTime() > (curDate); 
            }
          }
        }
      },
      handleQuery(){
        console.log(this.queryForm.invoiceNoStart,'queryForm.invoiceNoStart');
        this.queryForm.page = 1
        this.getList()
      },
      //重置
      handleReset() {
        this.startDate =  moment(new Date().getTime() - 365 * 4 * 24 * 3600 * 1000 - (1 * 24 * 3600 * 1000)).format('YYYY-MM-DD'),
        this.endDate =  moment(new Date().getTime()).format('YYYY-MM-DD'),
        this.queryForm = {
          showAllCompany: false,
          type: '2', //进项
          invoiceTimeStart: '',
          invoiceTimeEnd: '',
          invoiceType: '',
          buyerName: '',
          drawer:'', //开票人
          billingType :'', //开票类型
          invoiceStatus: [],
          invoiceNoStart: '',
          invoiceNoEnd: '',
          productName: '',
          invoiceCode: '',
          page: 1,
          rows: 20
        }
        this.$refs.queryForm.resetFields()
        this.getList()
      },
      // 切换表格分页
      changeSize(size) {
        this.queryForm.rows = size
        this.getList();
      },
      handleCurrentChange(current){
        this.queryForm.page = current
        this.getList();
      },
      selection(val){
        this.multipleSelection = val
        // this.multipleSelection = val.map(e=>{return e.id});
      },
      beforeQuery(){
        if(!this.getToken('companyId')){
          // einvAlert.warning("提示","请设置当前公司")
          this.$router.push('/home')
          // localStorage.setItem('sideLink', '/home')
          return false;
        }
        if(this.queryForm.invoiceNoStart !== '' && this.queryForm.invoiceNoEnd !== ''
            && Number(this.queryForm.invoiceNoStart) > Number(this.queryForm.invoiceNoEnd)){
          einvAlert.warning("提示","起始发票号码不能大于结束发票号码")
           return false;
        }
        if (this.startDate  == null || !this.startDate) {
          einvAlert.warning("提示","请选择开票开始日期")
          return false;
        }
        if (this.endDate == null || !this.endDate) {
          einvAlert.warning("提示","请选择开票结束日期")
          return false;
        }
        if (this.startDate !== undefined && this.startDate !== null) {
          this.queryForm.invoiceTimeStart = this.startDate + ' 00:00:00'
        }else{
          this.queryForm.invoiceTimeStart = ''
        }
        if (this.endDate !== undefined && this.endDate !== null) {
          this.queryForm.invoiceTimeEnd =this.endDate + ' 23:59:59'
        }else{
          this.queryForm.invoiceTimeEnd = ''
        }
        return true;
      },
      // 销项电子发票列表
      getList() {
        if(!this.beforeQuery()){
          return;
        }
        this.isLoading = true;
        this.isExport = false;
        this.$store.dispatch("FindEInv",
            {
              agencyCompanyId: this.getToken('agencyCompanyId'),
              companyId: this.getToken('companyId'),
              userId: this.getToken('userId'),
              userName: this.getToken('userName'),
              ...this.queryForm,
              invoiceStatus:this.changeInvoiceStatusToStr(this.queryForm.invoiceStatus)
            }
        ).then(res => {
          if (res.success) {
            this.isLoading = false;
            this.tableData = res.data
            this.total = res.totalCount
            if(res.data.length>0){
              this.isExport = true;
            }
            if(res.data.length == '0' && res.totalCount>0 && this.queryForm.page>1){
              this.queryForm.page = this.queryForm.page -1;
              this.getList();
            }
          } else {
            einvAlert.error("提示",res.msg)
          }
          this.exportFrom = this.queryForm
        }).catch(err => {
           this.isLoading = false;
           this.isExport = true;
          //einvAlert.error("提示",err)
        })
      },
      batchExport(){
        if(!this.isExport){
          //einvAlert.warning('提示','当前无数据，请检查',true,'确定')
          einvAlert.error("提示",'当前无数据，请检查')
          return
        }
        this.exportVis = true
      },
      selectType(value){
        this.exportType = value
      },
      //导出 查询后的数据
      confirmExport() {
        if(!this.beforeQuery()){
          return;
        }
        this.isdeling = true
        if(this.exportType === '1' && this.multipleSelection.length === 0){
          einvAlert.warning('提示','请先勾选',true,'确定')
          this.isdeling = false
          this.exportVis = false
          return
        }
        var ids ='',invoiceTime='';
        this.multipleSelection.forEach(e=>{
          ids += e.id+',',
          invoiceTime += e.invoiceTime.slice(0,10)+','
        })
        ids = ids.slice(0,ids.lastIndexOf(','))
        invoiceTime = invoiceTime.slice(0,invoiceTime.lastIndexOf(','))
        axios({
          method: 'get',
          url: '/api/einvoice/einv/batchExport',
          responseType: 'blob',
          params: {
            agencyCompanyId: this.getToken('agencyCompanyId'),
            companyId: this.getToken('companyId'),
            userId: this.getToken('userId'),
            idList: this.exportType === '1'?ids:'',
            invoiceTimes : this.exportType === '1'? invoiceTime:'', 
            exportType: this.exportType,
            ...this.exportFrom,
            invoiceStatus:this.changeInvoiceStatusToStr(this.queryForm.invoiceStatus)
          },
          headers: {
            'Content-Type': "application/json;charset=utf-8",
            'Authorization': this.getCookies('Authorization'),
            'group' :process.env.VUE_APP_GROUP
          }
        }).then((res) => {
          let data = res.data
          let fileReader = new FileReader();
          fileReader.onload = function() {
            try {
              let jsonData = JSON.parse(this.result);  // 说明是普通对象数据，后台转换失败
              if (jsonData.status == '40301') { // 接口返回的错误信息
                einvAlert.error("错误",jsonData.message) // 弹出的提示信息
              }
            } catch (err) {   // 解析成对象失败，说明是正常的文件流
              let url = window.URL.createObjectURL(new Blob([data], { type: "application/vnd.openxmlformats-officedocument.spreadsheetml.sheet" }))
              let link = document.createElement('a')
              link.style.display = 'none'
              link.href = url;
              var now = window.formatDate(new Date(), 'yyyyMMddhhmmss')
              link.setAttribute('download', '增值税销项电票' + now + '.xls')
              document.body.appendChild(link)
              link.click()
              document.body.removeChild(link)
            }
          };
          fileReader.readAsText(data)  // 注意别落掉此代码，可以将 Blob 或者 File 对象转根据特殊的编码格式转化为内容(字符串形式)
          this.isdeling = false
          this.exportVis = false
        }).catch(err=>{
          this.isdeling = false
          //einvAlert.error("提示",err)
        })
      },
      batchDelete(){
        this.delDialog = true
      },
      printPDF(){
        this.isdeling = true
        var ids ='', invoiceTime='',invoiceType = '';
        this.multipleSelection.forEach(e=>{
          ids += e.id+',',
          invoiceTime += (e.invoiceTime.slice(0,10))+',',
          invoiceType += e.invoiceType+','
        })
        console.log(ids,'ids');
        console.log(invoiceTime,'ids');
        if(!ids){
          einvAlert.warning("提示","请至少勾选一条记录");
          this.isdeling = false
          return
        }
        ids = ids.slice(0,ids.lastIndexOf(','))
        invoiceTime = invoiceTime.slice(0,invoiceTime.lastIndexOf(','))
        var _this = this;
        if(invoiceType.indexOf("1")==-1 && invoiceType.indexOf("2")==-1&& invoiceType.indexOf("3")==-1&& invoiceType.indexOf("4")==-1){
          _this.confirmPrint(ids,invoiceTime,false);
        }else{
          einvAlert.confirm(function(){
                _this.confirmPrint(ids,invoiceTime,true)
              },
              "是否需要添加销售章",
              "需要",
              "不需要",
              function(){
                _this.confirmPrint(ids,invoiceTime,false);
              });
        }
        this.isdeling = false;
      },
      confirmPrint(ids,invoiceTime,isAddSeal){
        let win = window.open("/api/einvoice/einv/print?companyId=" + this.getToken('companyId') +
            "&ids=" + ids +"&invoiceTimes=" + invoiceTime + "&addSeal=" + isAddSeal + "&Token=" + this.getCookies('Authorization')+ "&Group=eayc",
            '_blank');
        let printTitle = this.printTitle;
        var num = this.multipleSelection.length;
        setTimeout(function () {
          win.document.title = printTitle;
        }, 800*num);
      },
      /* 智能取票 */
      getInvoiceBtn(){
        this.getInvoiceVis =true
        this.$refs.getInvoice.checkPermissionNum()
      },
      delete(row) {
        this.delDialog = true
        this.id = row.id
      },
      cancelDialog() { this.delDialog = false },
      confirmDialog() {
        this.isdeling = true
        if(this.multipleSelection.length === 0){
          this.cancelDialog()
          einvAlert.warning('提示','请先选择发票',true,'确定')
          this.isdeling = false
          return
        }
        this.$store.dispatch("DeleteEInv",{
          userId: this.getToken('userId'),
          userName: this.getToken('userName'),
          ids: this.multipleSelection.map(item => {
                  return item.id
                }).join(','),
          invoiceTimes: this.multipleSelection.map(item => {
                  return item.invoiceTime.slice(0,10)
                }).join(',')
        }).then(res => {
          if (res.success) {
            einvAlert.success("提示",'删除成功')
            this.delDialog = false
            this.getList()
          } else {
            einvAlert.error("提示",res.msg||res.message )
          }
          this.isdeling = false
        }).catch(err => {
          this.isdeling = false
          //einvAlert.error("提示",err)
        })
      },
      //点击预览获取文件
        getFileUrl(item){
          this.$refs.pdf.getFileUrl(item)
        },
      imports(){
        const _this = this
        this.setToken('preUploadClientId',this.requestFrom+"_"+this.getToken('companyId')+"_"+this.invoiceType+"_"+this.getToken('userId')) //保存传参标记 clientId
        this.$store.dispatch('IsHasTask',{
          clientId:this.getToken('preUploadClientId')
        }).then(res => {
          if(res.success){
            //弹窗-判断是否继续上次操作
            if(res.data.isHasTask){
              einvAlert.confirm(function(){_this.confirmHandle(res.data.toPath,_this.invoiceType)}
                  ,"您上次的发票导入操作尚未完成，是否需要继续上次操作？"
                  ,'确认'
                  ,'取消'
                  ,function(){_this.cancelHandle()})
            }else{
              this.importVis = true;
            }
          }else {
            res ? einvAlert.error("提示",res.msg||res.message) : einvAlert.error("提示",'未知异常，稍后再试')
          }

        }).catch(err =>{
          err ? einvAlert.error("提示",err.msg||err.message||err) : einvAlert.error("提示",'未知异常，稍后再试')

        })
      },
      /* 确认操作，继续上次操作 */
      confirmHandle(toPath,invoiceType){
        if(toPath=="1"){//发票矫正页面
          this.$router.push({
            path:'/writeOff/invoiceAccount/invoiceInfo',
            query:{
              /* 业务类型  1-进项，2-销项 */
              invoiceType:invoiceType,
              // visible:true
            }
          })
          this.setToken('uploadListShow',true)
        }else{
          this.$router.push({
            path:'/writeOff/invoiceAccount/invoiceInfo',
            query:{
              /* 业务类型  1-进项，2-销项 */
              invoiceType:invoiceType,
              // visible:false
            }
          })
          this.setToken('uploadListShow',false)
        }
      },
      /* 取消上次操作，清除上次数据，弹窗-上传图片 (已完成) */
      cancelHandle(){
        /* 调清除上次数据接口 */
        this.$store.dispatch('PostCancelUpload',{
          clientId:this.getToken('preUploadClientId')
        }).then(res =>{
          if(!res.success){
            einvAlert.error("提示",res.msg||res.message)
          }
        }).catch(err =>{
          err ? einvAlert.error("提示",err.msg||err.message||err) : einvAlert.error("提示",'未知异常，稍后再试')

        })
        this.importVis = true;
      },
      importCancel(){
        this.importVis = false;
      },
      exportCancel(){
        this.exportVis = false;
      },
      getInvoiceCancel(){
        this.getInvoiceVis = false
        // console.log('关闭了看状态',this.getInvoiceVis)
      },
      mockData(){
        this.mulSelectLoading = true;
        const options = [
          { label: '正常', value: '1,4' },
          { label: '已红冲', value: '3' },
          { label: '已作废', value: '2' },
          { label: '失控', value: '8' },
          // 正常、已作废、已红冲、失控
        ]

        this.selectOptions = options;
        this.mulSelectLoading = false;
        // this.mulSelecteds = ['cq','nj'];
      },
      updateMultipleSelect(val){
        this.queryForm.invoiceStatus=val;
      },
      changeInvoiceStatusToStr(val){
        let invoiceStatusStr ='';
        if(val!=null&&val.length>0){
          for(var i=0;i<val.length;i++){
            if(i==0&&val[i]!='全选'){
              invoiceStatusStr=val[0];
            }else if(i!=0){
              if(invoiceStatusStr==''){
                invoiceStatusStr=val[i];
              }else{
                invoiceStatusStr=val[i]+","+invoiceStatusStr;
              }
            }
          }
        }
        return invoiceStatusStr;
      },

      // 新增功能 - V1.5.0
      preview(item){  // 预览
        this.$refs.pdf.getFileUrl(item)
      },
      push(data){     // 推送
        if(this.getToken('hasJoinCompany') == 'false'){
          einvAlert.warning('提示','当前公司尚未通过开票接入申请,无法使用该模块','确定','我知道了')
          return false;
        }
        console.log(data);
        this.rowData = data;
        this.pushVis = true
      },
      pushCancel(){
        this.pushVis = false;
        this.rowData = {};
      },
      pushConfirm(email){
        this.isdeling = true
        this.$store.dispatch('PushPdf',{
          invoiceId: this.rowData.invoiceId,
          kprq: this.rowData.invoiceTime.slice(0,10),
          companyId: this.rowData.companyId,
          email:email,
        }).then(res => {
          if (res.success) {
            this.isdeling = false;
            this.pushVis = false;
            einvAlert.success("提示",res.msg || res.message)
          } else {
            einvAlert.error("提示",res.msg||res.message)
            this.isdeling = false;
            this.pushVis = false; // 没得到正确的图片地址，直接关闭弹窗
          }
        }).catch(err => {
          err ? einvAlert.error("提示",err.msg||err.message||err) : einvAlert.error("提示",'未知异常')
          this.pushVis = false; // 没得到正确的图片地址，直接关闭弹窗
          this.isdeling = false;
        }) 
      },
      redFlush(data){ // 红冲
        if(this.getToken('hasJoinCompany') == 'false'){
          einvAlert.warning('提示','当前公司尚未通过开票接入申请,无法使用该模块','确定','我知道了')
          return false;
        }
        this.$router.push({
          path:'/invoicing/negativeElectronicInvoice/edit',
          query:{
            fpdm:data.invoiceCode,
            fphm:data.invoiceNo,
            kprq:data.invoiceTime.slice(0,10),
            typeId: '0'+data.invoiceType, //“026”增值税电子普通发票；“028”增值税电子专用发票
            companyId: data.companyId,
          }
        })
        // console.log(data);
      },
    },
    mounted() {
      this.mockData();
      // console.log(this.isSearch,'this.isSearch');
      if(this.isSearch){
        // console.log('跳转过来的');
        this.startDate = this.$route.query.startDate
        this.endDate = this.$route.query.endDate
        this.$refs.search.handleClick()
        this.getList()
      } else {
        this.getList()
      }
    },
    watch:{
      queryForm:{
        deep: true,
        immediate:true,
        handler(){
          // 去掉输入框中首位空格
          let keys = Object.keys(this.queryForm);
          keys.forEach(item =>{
            if(typeof(this.queryForm[item])=='string'){
              this.queryForm[item] = this.queryForm[item].trim()
            }
          })
        }
      }
    }
  }
</script>
<style lang="less">
   .invoice-account{
     .el-tag{
       min-width: 3rem !important;
     }
   }
  .single_query{
    .el-form-item__content {
      width: 60% !important;
    }
  }
  .range-query{
    .el-form-item__content {
      width: 85% !important;
    }
    .filter-item{
      width: 45%;
    }
  }
  .button_query{
    float: right;
    .el-form-item__content {
      width: 100% !important;
    }
  }
</style>

<style lang="less" scoped>
  .tz {
    .el-form-item{
      margin-right: 0.2rem !important;
    }
  }
  .button{
    margin-right: 10px;
  }
  .batch_button{
    padding-top: 10px;
    margin-top: 15px;
    background: #ffffff;
    .btn1{
      margin-right: 10px;
      background: #409eff;
      border: 1px solid #409eff;
      border-radius: 8px;
      color: #ffffff;
      height: 40px;
      width: 120px;
    }
    .btn2{
      margin-right: 10px;
      background: #e8f5ff;
      border: 1px solid #409eff;
      border-radius: 8px;
      color: #409eff;
      height: 40px;
      width: 120px;
    }
    .btnBox {
      display: flex;
      justify-content: space-between;
      align-items: center;
      padding-right: 24px;
      .el-icon-question {
        color: #a7a7a7;
        font-size: 20px;
      }
      .el-icon-question:hover {
        color: #409eff;
        font-size: 20px;
      }

    }
  }
  .zhineng {
    position: relative;
  }
  .showAllCompany {
    position: relative;
    margin-left: 42%;
    margin-top: 0.7%;
  }
  .tip {
    position: absolute;
    top: 60px;
    right: 0;
    width: 300px;
    height: 175px;
    padding:17px 12px;
    background: #ffffff;
    border: 1px solid #dcdcdc;
    border-radius: 4px;
    box-shadow: -3px 1px 10px 0px rgba(169,169,169,0.26);
    font-size: 14px;
    line-height: 24px;
    z-index: 99;
    p {
      margin: 0;

    }

  }
   .table {
     padding: 24px 20px;
     padding-bottom: 3rem;
     background-color: #fff;
     .comp_table {
       border: 1px solid #ebeef5;
     };

   }
   .answer {
     text-align: center;
     padding: 24px 0 14px;
     font-size: 16px;
   }
   /deep/.removeControl {
      input::-webkit-outer-spin-button,
      input::-webkit-inner-spin-button {
        -webkit-appearance: none;
      }
      input[type="number"]{
        -moz-appearance: textfield;
      }
   }
  /deep/.el-table__body-wrapper{
    height: 410px;
    overflow-y: auto;
  }
  /deep/ .el-table__fixed-body-wrapper {
          overflow-y: auto; // fixed的列也要超出滚动
          height: 400px;  // 出现滚动条需要比表格短10px
        }
</style>
